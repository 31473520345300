import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function ElectricIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path d="m11 14.5l-5.925-.75q-.625-.075-.813-.675t.263-1.025l10.225-9.8q.125-.125.3-.187T15.525 2q.5 0 .763.425t.012.875L13 9.5l5.925.75q.625.075.813.675t-.263 1.025L9.25 21.75q-.125.125-.3.188T8.475 22q-.5 0-.763-.425T7.7 20.7zm1.55 1.325l4.025-3.85l-6.725-.85L11.425 8.2l-4 3.85l6.7.825zM12 12"></path>
    </SVGIcon>
  );
}
