import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function DpeIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="m 4,6.0000137 q -0.425,0 -0.712,0.288 -0.287,0.288 -0.288,0.712 -10e-4,0.424 0.288,0.712 0.289,0.288 0.712,0.288 h 4 q 0.425,0 0.713,-0.288 0.288,-0.288 0.287,-0.712 -10e-4,-0.424 -0.288,-0.713 -0.287,-0.289 -0.712,-0.287 z m 0,5.0000003 q -0.425,0 -0.712,0.288 -0.287,0.288 -0.288,0.712 -10e-4,0.424 0.288,0.712 0.289,0.288 0.712,0.288 h 10 q 0.425,0 0.713,-0.288 0.288,-0.288 0.287,-0.712 -10e-4,-0.424 -0.288,-0.713 -0.287,-0.289 -0.712,-0.287 z m 0,5 q -0.425,0 -0.712,0.288 -0.287,0.288 -0.288,0.712 -10e-4,0.424 0.288,0.712 0.289,0.288 0.712,0.288 h 16 q 0.425,0 0.713,-0.288 0.288,-0.288 0.287,-0.712 -0.001,-0.424 -0.288,-0.713 -0.287,-0.289 -0.712,-0.287 z"
      />
    </SVGIcon>
  );
}
