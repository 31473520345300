import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function QuestionBoxIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="M5 21q-.825 0-1.412-.587T3 19v-3q0-.425.288-.712T4 15t.713.288T5 16v3h3q.425 0 .713.288T9 20t-.288.713T8 21zm14 0h-3q-.425 0-.712-.288T15 20t.288-.712T16 19h3v-3q0-.425.288-.712T20 15t.713.288T21 16v3q0 .825-.587 1.413T19 21M3 5q0-.825.588-1.412T5 3h3q.425 0 .713.288T9 4t-.288.713T8 5H5v3q0 .425-.288.713T4 9t-.712-.288T3 8zm18 0v3q0 .425-.288.713T20 9t-.712-.288T19 8V5h-3q-.425 0-.712-.288T15 4t.288-.712T16 3h3q.825 0 1.413.588T21 5m-9 13q.525 0 .888-.363t.362-.887t-.363-.888T12 15.5t-.888.363t-.362.887t.363.888T12 18m0-10.3q.65 0 1.138.4t.487 1.025q0 .575-.363 1.025t-.787.825q-.65.575-.987 1.088T11.1 13.3q-.025.35.25.613t.65.262q.35 0 .638-.25t.337-.625q.05-.425.3-.75t.725-.8q.875-.875 1.163-1.412t.287-1.288q0-1.35-.975-2.2T12 6q-1.025 0-1.837.463T8.925 7.775q-.15.3-.012.613t.462.437t.663 0t.537-.4q.275-.35.638-.537T12 7.7"
      />
    </SVGIcon>
  );
}
