import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function SentimentVeryDissatisfiedIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="m8.9 10.95l.525.525q.225.225.525.225t.525-.225t.225-.525t-.225-.525L9.95 9.9l.55-.575q.2-.225.2-.525t-.225-.525t-.525-.225t-.525.225L8.9 8.8l-.575-.55q-.225-.2-.525-.2t-.525.225q-.2.2-.213.513t.188.537l.55.575l-.525.525q-.225.225-.225.525t.225.525q.2.2.512.213t.538-.188zm6.2 0l.575.55q.225.2.525.2t.525-.225t.225-.525t-.225-.525L16.2 9.9l.55-.575q.2-.225.2-.525t-.225-.525q-.2-.2-.513-.213t-.537.188l-.575.55l-.525-.525q-.225-.225-.525-.225t-.525.225q-.2.2-.213.513t.188.537l.55.575l-.525.525q-.225.225-.225.525t.225.525t.525.225t.525-.225zM12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-8.5q-1.425 0-2.662.7T7.35 16.1q-.15.3.025.6t.525.3h8.2q.35 0 .525-.3t.025-.6q-.75-1.2-1.975-1.9T12 13.5"
      />
    </SVGIcon>
  );
}
