import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function ProjectSolidIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <g fill="currentColor">
        <path d="M19.006 3.705a.75.75 0 1 0-.512-1.41L6 6.838V3a.75.75 0 0 0-.75-.75h-1.5A.75.75 0 0 0 3 3v4.93l-1.006.365a.75.75 0 0 0 .512 1.41z" />
        <path
          fillRule="evenodd"
          d="M3.019 11.115L18 5.666v3.421l4.006 1.457a.75.75 0 1 1-.512 1.41l-.494-.18v8.475h.75a.75.75 0 0 1 0 1.5H2.25a.75.75 0 0 1 0-1.5H3V11.12zM18 20.25v-9.565l1.5.545v9.02zm-9-6a.75.75 0 0 0-.75.75v4.5c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75V15a.75.75 0 0 0-.75-.75z"
          clipRule="evenodd"
        />
      </g>
    </SVGIcon>
  );
}
