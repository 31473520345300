import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function SearchInsightsIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="m 13.575,16 q -2.5,0 -4.25,-1.75 Q 7.575,12.5 7.575,10 7.575,7.5 9.325,5.75 11.075,4 13.575,4 q 2.5,0 4.25,1.75 1.75,1.75 1.75,4.25 0,2.5 -1.75,4.25 -1.75,1.75 -4.25,1.75 m 0,-3 Q 14,13 14.287,12.712 14.574,12.424 14.575,12 V 7 q 0,-0.425 -0.288,-0.712 Q 13.999,6.001 13.575,6 13.151,5.999 12.862,6.288 12.573,6.577 12.575,7 v 5 q 0,0.425 0.288,0.713 0.288,0.288 0.712,0.287 m 3.5,0 Q 17.5,13 17.787,12.712 18.074,12.424 18.075,12 V 9 q 0,-0.425 -0.288,-0.712 Q 17.499,8.001 17.075,8 16.651,7.999 16.362,8.288 16.073,8.577 16.075,9 v 3 q 0,0.425 0.288,0.713 0.288,0.288 0.712,0.287 m -7,0 Q 10.5,13 10.787,12.712 11.074,12.424 11.075,12 v -2 q 0,-0.425 -0.288,-0.712 Q 10.499,9.001 10.075,9 9.651,8.999 9.362,9.288 9.073,9.577 9.075,10 v 2 q 0,0.425 0.288,0.713 0.288,0.288 0.712,0.287 m 3.5,5 q 3.35,0 5.675,-2.325 Q 21.575,13.35 21.575,10 21.575,6.65 19.25,4.325 16.925,2 13.575,2 10.225,2 7.9,4.325 5.575,6.65 5.575,10 q 0,1.4 0.437,2.65 0.437,1.25 1.238,2.275 L 2.275,19.9 Q 2,20.175 2,20.6 q 0,0.425 0.275,0.7 0.275,0.275 0.7,0.275 0.425,0 0.7,-0.275 L 8.65,16.325 q 1.025,0.8 2.275,1.238 1.25,0.438 2.65,0.437"
      />
    </SVGIcon>
  );
}
