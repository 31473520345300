import React from 'react';
import clsx from 'clsx';
import useRoute from '@/Hooks/useRoute';
import SidebarMenuItem from '@/Components/Molecules/SidebarMenuItem';
import { router } from '@inertiajs/react';
import ApplicationLogo from '@/Components/Atoms/ApplicationLogo';
import SidebarLink from '@/Components/Molecules/SidebarLink';
import { Avatar, AvatarFallback } from '@/Components/Atoms/Avatar';
import useTypedPage from '@/Hooks/useTypedPage';
import { Button } from '@/Components/Atoms/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/Components/Atoms/DropdownMenu';
import Link from '@/Components/Atoms/Link';

interface Props {
  className?: string;
}

export default function NavigationMenu({ className }: Props) {
  const route = useRoute();
  const {
    auth: {
      user,
      permissions: {
        household: { view: canViewHousehold },
        project: { create: canCreateProject },
      },
    },
  } = useTypedPage().props;

  const logout = (e: Event) => {
    e.preventDefault();
    router.post(route('logout'));
  };

  return (
    <nav
      className={clsx(
        className,
        "z-50 grid grid-rows-[auto_auto_1fr_auto_auto] gap-y-4 py-3 [grid-template-areas:'logo''button''menu''user''links'] lg:gap-y-6",
      )}
    >
      <div className="mt-6 hidden [grid-area:logo] md:block">
        <Link href={route('dashboard')} className="flex justify-center">
          <ApplicationLogo className="block h-9 w-auto" />
        </Link>
      </div>

      <div className="mx-1 my-4 flex content-center items-center justify-center [grid-area:button]">
        <Button variant="secondary" size="lg" asChild>
          <Link href={route('project.new')}>Nouveau projet</Link>
        </Button>
      </div>

      <div className="-mt-3 h-full self-center overflow-y-auto pt-3 [grid-area:menu]">
        <div className="flex min-h-fit flex-1 flex-col gap-3 px-1 font-semibold md:gap-6">
          <SidebarMenuItem
            route={route('dashboard')}
            icon="project-solid"
            label="Mes projets"
            active={route().current('dashboard')}
          />
          {canViewHousehold && (
            <SidebarMenuItem
              route={route('household')}
              icon="household"
              label="Mon foyer"
              active={route().current('household')}
            />
          )}
        </div>
      </div>

      {user && (
        <DropdownMenu>
          <DropdownMenuTrigger className="m-2 flex flex-col items-center gap-y-2 rounded py-2 text-muted-foreground [grid-area:user] hover:text-primary-foreground-accent max-md:bg-gray-light">
            <>
              <Avatar>
                {/* <AvatarImage src="" /> */}
                <AvatarFallback className="font-semibold tracking-wider">
                  {user.firstname.at(0)?.toUpperCase()}
                  {user.lastname.at(0)?.toUpperCase()}
                </AvatarFallback>
              </Avatar>
              Mon compte
            </>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            onCloseAutoFocus={(ev) => {
              ev.preventDefault();
            }}
          >
            <DropdownMenuItem asChild>
              <Link href={route('profile.show')} className="focus-visible:ring-transparent">
                Gérer mon compte
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem onSelect={logout}>Se déconnecter</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}

      <div className="flex flex-col gap-2 px-2 [grid-area:links] md:gap-0 md:px-3 lg:px-4">
        {/* <SidebarLink route={route('profile.show')} icon="settings" label="Paramètres" /> */}
        <SidebarLink
          route={'https://renodit.fr/mentions-legales'}
          icon="balance"
          label="Mentions légales"
          external
        />
      </div>
    </nav>
  );
}
